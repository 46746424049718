import React from 'react';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useSelector} from 'react-redux';
import CaregiverForm, {
  defaultCaregiver,
} from 'src/modules/patients/components/caregiver-form';
import ClientForm, {fields} from 'src/modules/patients/components/client-form';
import * as Yup from 'yup';
import {
  Caregiver,
  Patient,
  Medication,
  Diagnosis,
  InstanceDiagnosis,
  Insurance,
  Instance,
  CareTeamParticipant,
  Credential,
  Taggable,
} from 'src/models';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {of, mergeMap} from 'rxjs';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import * as FirstName from 'src/hook-form-inputs/first-name';
import * as LastName from 'src/hook-form-inputs/last-name';
import ReviewPage from '../../components/review-page';
import withState from 'src/redux/wrapper';
import MedicalForm, {defaultMedication} from '../../components/medical-form';
import ClinicalForm from 'src/modules/patients/components/clinical-form';
import _ from 'lodash';
import StepForm from 'src/hook-form/step-form';
import {
  defaultPayer,
  defaultSubscriber,
} from 'src/modules/billing/components/insurance-form';
import {Q} from '@nozbe/watermelondb';
import InsuranceForm from 'src/modules/patients/components/insurance-form';
import {defaultDiagnosis} from 'src/modules/patients/components/diagnoses-form';
import {NPI} from 'src/hook-form-inputs/';

const PatientFormScreen = ({
  route,
  role,
  profile,
  navigation,
  patient,
  caregivers,
  medications,
  diagnoses,
  insurances,
  instance,
  tags,
}: any) => {
  const {id = null}: any = route?.params || {};
  const database = useDatabase();

  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const validationSchema = Yup.object({
    firstName: FirstName.validation(),
    lastName: LastName.validation(),
    insurances: Yup.array().of(
      Yup.object().shape({
        payerId: Yup.string(),
        planId: Yup.string().when('payerId', {
          is: (payerId: any) => payerId,
          then: () => Yup.string().required('Please Select a Plan.'),
        }),
      }),
    ),
    primary: Yup.object({
      npi: NPI.validation(),
    }),
    referring: Yup.object({
      npi: NPI.validation(),
    }),
  });

  const navigateBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      if (id) {
        navigation.navigate('PatientProfile', {
          screen: 'Summary',
          patientId: id,
        });
      } else {
        navigation.navigate('PatientList');
      }
    }
  };

  const onSubmit = async (values: any) => {
    let primary = {};
    if (values.primaryId === 'other' && values.primary.firstName !== '') {
      primary = await database?.write(async () => {
        return database?.get(Credential.table).create(entity => {
          entity.partition = selectedGroup;
          entity.firstName = values.primary.firstName;
          entity.middleName = values.primary.middleName;
          entity.lastName = values.primary.lastName;
          entity.mobilePhone = values.primary.mobilePhone;
          entity.email = values.primary.email;
          entity.address = values.primary.address;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
    } else {
      primary = {
        id: values.primaryId,
      };
    }
    let referring = {};
    if (values.referringId) {
      if (values.referringId === 'other' && !values.sameAsPrimary) {
        referring = await database?.write(async () => {
          return database?.get(Credential.table).create(entity => {
            entity.partition = selectedGroup;
            entity.firstName = values.referring.firstName;
            entity.middleName = values.referring.middleName;
            entity.lastName = values.referring.lastName;
            entity.mobilePhone = values.referring.mobilePhone;
            entity.email = values.referring.email;
            entity.address = values.referring.address;
            entity.tin = values.referring?.tin;
            entity.npi = values.referring?.npi;
            entity.ssn = values.referring?.ssn;
            entity.createdBy = userId;
            entity.updatedBy = userId;
          });
        });
      } else if (!values.sameAsPrimary) {
        const referringEntity = await database
          ?.get(Credential.table)
          .find(values.referringId);
        await referringEntity.updateEntity({
          firstName: values.referring?.firstName,
          middleName: values.referring?.middleName,
          lastName: values.referring?.lastName,
          mobilePhone: values.referring?.mobilePhone,
          email: values.referring?.email,
          address: values.referring?.address,
          tin: values.referring?.tin,
          npi: values.referring?.npi,
          ssn: values.referring?.ssn,
        });
      }
    }
    if (id) {
      await patient.updateEntity({
        partition: selectedGroup,
        identifier: values.identifier,
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        gender: values.gender,
        ssn: values.ssn,
        state: values.state,
        birthDate: values.birthDate,
        schoolId: values.schoolId.address,
        staffId: values.staff,
        intakeDate: values.intakeDate,
        primaryId: primary?.id ?? '',
        sameAsPrimary: values.sameAsPrimary,
        referringId: referring?.id || values.referringId,
      });
      for (const caregiver of values.caregivers) {
        const deletedCaregivers = _.differenceWith(
          caregivers,
          values.caregivers,
          (value1, value2) => {
            return value1.id === value2._id;
          },
        );
        for (const deletedCaregiver of deletedCaregivers) {
          await deletedCaregiver.delete();
        }
        if (caregiver?._id) {
          const entity = await database
            .get(Caregiver.table)
            .find(caregiver._id);

          entity.updateEntity({
            firstName: caregiver.firstName,
            lastName: caregiver.lastName,
            email: caregiver.email,
            relationship: caregiver.relationship,
            mobilePhone: caregiver.mobilePhone,
            address: caregiver.address,
            workAddress: caregiver.workAddress,
            deletedAt: caregiver?.deletedAt ?? null,
          });
        } else {
          await database?.write(async () => {
            await database?.get(Caregiver.table).create(entity => {
              entity.partition = selectedGroup;
              entity.firstName = caregiver.firstName;
              entity.lastName = caregiver.lastName;
              entity.email = caregiver.email;
              entity.relationship = caregiver.relationship;
              entity.mobilePhone = caregiver.mobilePhone;
              entity.address = caregiver.address;
              entity.workAddress = caregiver.workAddress;

              entity.patient.id = patient.id;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          });
        }
      }
      for (const medication of values.medications) {
        if (medication?._id) {
          const entity = await database
            .get(Medication.table)
            .find(medication._id);

          entity.updateEntity({
            medication: medication.medication,
            dosage: medication.dosage,
            frequency: medication.frequency,
            deletedAt: medication?.deletedAt ?? null,
          });
        } else {
          await database?.write(async () => {
            await database?.get(Medication.table).create(entity => {
              entity.partition = selectedGroup;
              entity.medication = medication.medication;
              entity.dosage = medication.dosage;
              entity.frequency = medication.frequency;

              entity.patient.id = patient.id;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          });
        }
      }
      const deleteTags = _.differenceWith(
        tags,
        values.tags,
        (value1, value2) => {
          return value1.id === value2;
        },
      );
      const differenceTags: string[] = _.differenceWith(
        values.tags,
        tags,
        (value1, value2) => {
          return value1 === value2.id;
        },
      );

      for (const tag of deleteTags) {
        const profileTags = await database
          .get(Taggable.table)
          .query(Q.where('tag_id', Q.eq(tag.id)))
          .fetch();
        for (const profileTag of profileTags) {
          await profileTag.delete();
        }
      }
      for (const tag of differenceTags) {
        database.write(async () => {
          await database?.get(Taggable.table).create(entity => {
            entity.partition = selectedGroup;
            entity.entityId = patient.id;
            entity.entity = 'patient';
            entity.tagId = tag;
            entity.createdBy = userId;
            entity.updatedBy = userId;
          });
        });
      }
      for (const [index, diagnosis] of values.diagnoses.entries()) {
        if (diagnosis.instanceDiagnosisId) {
          const diagnosisObject = await database
            .get(InstanceDiagnosis.table)
            .find(diagnosis.instanceDiagnosisId);
          if (diagnosis?._id) {
            const entity = await database
              .get(Diagnosis.table)
              .find(diagnosis._id);
            if (diagnosis?.deletedAt) {
              await entity.delete();
            } else {
              entity.updateEntity({
                diagnosis: diagnosisObject.diagnosis,
                severity: diagnosis.severity,
                diagnosisDate: diagnosis.diagnosisDate,
                rank: index.toString(),
                deletedAt: diagnosis?.deletedAt ?? null,
                instanceDiagnosisId: diagnosis.instanceDiagnosisId,
              });
            }
          } else {
            await database?.write(async () => {
              await database?.get(Diagnosis.table).create(entity => {
                entity.partition = selectedGroup;
                entity.diagnosis = diagnosisObject.diagnosis;
                entity.severity = diagnosis.severity;
                entity.diagnosisDate = diagnosis.diagnosisDate;
                entity.rank = index.toString();
                entity.patient.id = patient.id;
                entity.instanceDiagnosisId = diagnosis.instanceDiagnosisId;
                entity.createdBy = userId;
                entity.updatedBy = userId;
              });
            });
          }
        }
      }
      for (const [index, insurance] of values.insurances.entries()) {
        if (insurance._id) {
          const entity = await database
            .get(Insurance.table)
            .find(insurance._id);
          if (insurance.deletedAt) {
            await entity.delete();
          } else {
            entity.updateEntity({
              payerId: insurance.payerId,
              planId: insurance.planId,
              patientId: patient.id,
              groupNumber: insurance.groupNumber,
              memberId: insurance.memberId,
              startDate: insurance.startDate,
              endDate: insurance.endDate,
              rank: index.toString(),
              subscriber: insurance.subscriber,
            });
          }
        } else {
          if (insurance.payerId) {
            await database?.write(async () => {
              return database?.get(Insurance.table).create(entity => {
                entity.partition = selectedGroup;
                entity.groupNumber = insurance.groupNumber;
                entity.memberId = insurance.memberId;
                entity.payerId = insurance.payerId;
                entity.planId = insurance.planId;
                entity.startDate = insurance.startDate;
                entity.endDate = insurance.endDate;
                entity.rank = index.toString();
                entity.subscriber = insurance.subscriber;
                entity.patientId = patient.id;
                entity.createdBy = userId;
                entity.updatedBy = userId;
              });
            });
          }
        }
      }
    } else {
      await database?.write(async () => {
        const createdPatient = await database
          ?.get(Patient.table)
          .create(entity => {
            entity.partition = selectedGroup;
            entity.identifier = values.identifier;
            entity.firstName = values.firstName;
            entity.middleName = values.middleName;
            entity.lastName = values.lastName;
            entity.gender = values.gender;
            entity.ssn = values.ssn;
            entity.birthDate = values.birthDate;
            entity.schoolId = values.schoolId.address;
            entity.intakeDate = values.intakeDate;
            entity.staffId = values.staff;
            entity.primaryId = primary?.id ?? '';
            entity.sameAsPrimary = values.sameAsPrimary;
            entity.referringId = referring?.id ?? '';
            entity.state = 'active';
            entity.createdBy = userId;
            entity.updatedBy = userId;
          });
        for (const caregiver of values.caregivers) {
          await database?.get(Caregiver.table).create(entity => {
            entity.partition = selectedGroup;
            entity.firstName = caregiver.firstName;
            entity.lastName = caregiver.lastName;
            entity.email = caregiver.email;
            entity.relationship = caregiver.relationship;
            entity.mobilePhone = caregiver.mobilePhone;
            entity.address = caregiver.address;
            entity.workAddress = caregiver.workAddress;

            entity.patient.id = createdPatient.id;
            entity.createdBy = userId;
            entity.updatedBy = userId;
          });
        }
        for (const tag of values.tags) {
          await database?.get(Taggable.table).create(entity => {
            entity.partition = selectedGroup;
            entity.entityId = createdPatient.id;
            entity.entity = 'patient';
            entity.tagId = tag;
            entity.createdBy = userId;
            entity.updatedBy = userId;
          });
        }
        for (const medication of values.medications) {
          await database?.get(Medication.table).create(entity => {
            entity.partition = selectedGroup;
            entity.medication = medication.medication;
            entity.dosage = medication.dosage;
            entity.frequency = medication.frequency;

            entity.patient.id = createdPatient.id;
            entity.createdBy = userId;
            entity.updatedBy = userId;
          });
        }
        for (const [index, diagnosis] of values.diagnoses.entries()) {
          if (diagnosis.instanceDiagnosisId) {
            const diagnosisObject = await database
              .get(InstanceDiagnosis.table)
              .find(diagnosis.instanceDiagnosisId);

            await database?.get(Diagnosis.table).create(entity => {
              entity.partition = selectedGroup;
              entity.diagnosis = diagnosisObject.diagnosis;
              entity.severity = diagnosis.severity;
              entity.diagnosisDate = diagnosis.diagnosisDate;
              entity.instanceDiagnosisId = diagnosis.instanceDiagnosisId;
              entity.rank = index.toString();

              entity.patient.id = createdPatient.id;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          }
          if (role?.patientCreate && role?.patientAssignedOnly) {
            await database.get(CareTeamParticipant.table).create(entity => {
              entity.partition = selectedGroup;
              entity.patient.id = createdPatient.id;
              entity.user.id = profile.id;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          }
        }
        for (const [index, insurance] of values.insurances.entries()) {
          if (insurance.payerId) {
            await database?.get(Insurance.table).create(entity => {
              entity.partition = selectedGroup;
              entity.groupNumber = insurance.groupNumber;
              entity.memberId = insurance.memberId;
              entity.payerId = insurance.payerId;
              entity.planId = insurance.planId;
              entity.startDate = insurance.startDate;
              entity.endDate = insurance.endDate;
              entity.rank = index.toString();
              entity.subscriber = insurance.subscriber;
              entity.patientId = createdPatient.id;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          }
        }
      });
    }
    navigateBack();
  };

  const steps = [
    {
      step: 'Client',
      fields: fields,
      form: ClientForm,
      validated: false,
      touched: false,
    },
    {
      step: 'Caregivers',
      fields: [],
      form: CaregiverForm,
      validated: true,
      touched: false,
    },
    // {
    //   step: 'Home Life',
    //   sections: Homelife.sections,
    // },
    {
      step: 'Medical',
      fields: [],
      form: MedicalForm,
      validated: true,
      touched: false,
    },
    {
      step: 'Clinical',
      fields: [],
      form: ClinicalForm,
      validated: true,
      touched: false,
    },
    // {
    //   step: 'Medical',
    //   sections: Medical.medicalFields,
    // },
    // {
    //   step: 'Clinical',
    //   sections: Clinic.sections,
    // },
  ];

  if (instance.billing) {
    steps.push({
      step: 'Insurance',
      fields: ['insurances'],
      form: InsuranceForm,
      validated: false,
      touched: false,
    });
  }

  return (
    <StepForm
      onSubmit={onSubmit}
      defaultSteps={[
        ...steps,
        {
          step: 'Review',
          fields: [],
          form: ReviewPage,
          validated: false,
          touched: false,
          extras: {
            billing: instance.billing,
          },
        },
      ]}
      defaultValues={{
        identifier: patient.identifier,
        firstName: patient.firstName,
        middleName: patient.middleName,
        lastName: patient.lastName,
        gender: patient.gender,
        birthDate: patient.birthDate,
        ssn: patient.ssn,
        state: patient.state,
        staff: patient.staffId,
        intakeDate: patient.intakeDate > 0 ? patient.intakeDate : undefined,
        primaryId: patient.primaryId,
        schoolId: {address: patient.schoolId},
        tags: tags.length ? tags.map((tag: any) => tag?.id) : [],
        primary: {
          firstName: '',
          middleName: '',
          lastName: '',
          mobilePhone: '',
          email: '',
          address: {},
          tin: '',
          npi: '',
          ssn: '',
          taxonomy: [],
        },
        sameAsPrimary: patient.sameAsPrimary,
        referringId: patient.referringId,
        referring: {
          firstName: '',
          middleName: '',
          lastName: '',
          mobilePhone: '',
          email: '',
          address: {},
          tin: '',
          npi: '',
          ssn: '',
          taxonomy: [],
        },
        caregivers: caregivers.length
          ? caregivers.map((caregiver: any) => ({
              _id: caregiver?.id,
              firstName: caregiver?.firstName,
              lastName: caregiver?.lastName,
              mobilePhone: caregiver?.mobilePhone,
              email: caregiver?.email,
              relationship: caregiver?.relationship,
              address: caregiver?.address,
              workAddress: caregiver?.workAddress,
            }))
          : [defaultCaregiver],
        medications: medications?.length
          ? medications.map((medication: any) => ({
              _id: medication?.id,
              medication: medication?.medication || '',
              dosage: medication?.dosage || '',
              frequency: medication?.dosage || '',
            }))
          : [defaultMedication],
        diagnoses: diagnoses?.length
          ? diagnoses.map((diagnosis: any) => ({
              _id: diagnosis?.id,
              diagnosis: diagnosis.diagnosis || '',
              severity: diagnosis.severity || '',
              diagnosisDate: diagnosis.diagnosisDate || new Date(),
              instanceDiagnosisId: diagnosis.instanceDiagnosisId,
            }))
          : [defaultDiagnosis],
        insurances: insurances.length
          ? insurances.map((insurance: any) => ({
              _id: insurance.id,
              groupNumber: insurance.groupNumber,
              memberId: insurance.memberId,
              startDate:
                insurance.startDate > 0 ? insurance.startDate : undefined,
              endDate: insurance.endDate > 0 ? insurance.endDate : undefined,
              planId: insurance.planId,
              payerId: insurance.payer.id,
              subscriber: insurance.subscriber || defaultSubscriber,
            }))
          : [defaultPayer],
        reorderDiagnoses: false,
        reorderPayers: false,
      }}
      validationSchema={validationSchema}
      navigateBack={navigateBack}
    />
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({database, authentication}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of(),
    instance: database
      ?.get(Instance.table)
      .query(Q.where('_partition', authentication.selectedGroup))
      .observe()
      .pipe(mergeMap(x => x)),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables(['route'], ({route, database}: any) => {
    if (route.params?.id) {
      return {
        patient: database.get('patients').findAndObserve(route.params.id),
      };
    }
    return {
      patient: of({
        identifier: '',
        firstName: '',
        lastName: '',
        gender: '',
        birthDate: new Date(),
        permission: '',
        position: '',
        mobilePhone: '',
        address: {
          use: '',
          country: 'US',
          line: '',
          city: '',
          state: '',
          postalCode: '',
        },
        startDate: new Date(),
        staff: {
          id: '',
        },
        ssn: '',
        diagnosis: '',
        diagnoses: [],
        primaryId: '',
        sameAsPrimary: true,
        referringId: '',
      }),
    };
  }),
  withObservables(['patient', 'route'], ({patient}) => {
    if (patient.id) {
      return {
        caregivers: patient.activeCaregivers,
        medications: patient.activeMedications,
        diagnoses: patient.activeDiagnoses,
        insurances: patient.activeInsurances,
        tags: patient.activeTags,
      };
    }
    return {
      caregivers: of([]),
      medications: of([]),
      diagnoses: of([]),
      insurances: of([]),
      tags: of([]),
    };
  }),
)(PatientFormScreen);
