import React, {useEffect, Dispatch, SetStateAction, useState} from 'react';
import {
  View,
  Text,
  SafeAreaView,
  TouchableOpacity,
  Platform,
  Keyboard,
  ScrollView,
  FlatList,
} from 'react-native';
import {Typography} from 'src/styles';
import {FormProvider, useForm} from 'react-hook-form';
import {Drawer} from 'src/design-system';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import NoteTemplateVersion from 'src/hook-form-inputs/note-template-version';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import SessionNoteListItem from 'src/modules/session/components/session-note-list-item';
import {TextInput} from 'react-native-paper';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {Note} from 'src/models/note';
import {useDispatch, useSelector} from 'react-redux';
import {setNote} from 'src/redux/session';
import {useDimensions} from '@react-native-community/hooks';

const isWeb = Platform.OS === 'web';
const isAndroid = Platform.OS === 'android';

interface Props {
  session: any;
  openNote: [boolean, Dispatch<SetStateAction<boolean>>];
  notes: any;
  templateVersion: any;
  appointment: any;
}

const SessionNote = ({
  session,
  appointment,
  openNote: [openNote, setOpenNote],
  notes,
  templateVersion,
}: Props) => {
  const styles = useStyle();
  const translations = useTranslations();
  const dimensions = useDimensions();
  const database = useDatabase();
  const dispatch = useDispatch();
  const {userId, selectedGroup} = useSelector(state => state.authentication);
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const {inSession} = useSelector(state => state);
  const statefulNote = inSession?.notes?.[session.id] || '';
  const isSmallSize = dimensions.window.width <= 768;
  const [editingNote, setEditingNote] = useState<boolean>(false);

  const methods = useForm({
    defaultValues: {
      note: statefulNote || '',
      noteTemplateVersionId: session?.noteTemplateVersionId,
      templateText: session?.note,
    },
  });

  useEffect(() => {
    if (statefulNote) {
      methods.setValue('note', statefulNote);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statefulNote]);

  useEffect(() => {
    const keyboardWillShowSub = Keyboard.addListener(
      isAndroid ? 'keyboardDidShow' : 'keyboardWillShow',
      keyboardWillShow,
    );
    const keyboardWillHideSub = Keyboard.addListener(
      isAndroid ? 'keyboardDidHide' : 'keyboardWillHide',
      keyboardWillHide,
    );

    return () => {
      keyboardWillShowSub.remove();
      keyboardWillHideSub.remove();
    };
  }, []);

  const keyboardWillShow = event => {
    setKeyboardHeight(event.endCoordinates.height);
  };

  const keyboardWillHide = () => {
    setKeyboardHeight(0);
  };

  useEffect(() => {
    methods.setValue('templateText', templateVersion.template);
    session.updateEntity({
      note: templateVersion.template,
      noteTemplateId: templateVersion.noteTemplateId,
    });
    appointment.updateEntity({
      noteTemplateId: templateVersion.noteTemplateId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateVersion]);

  useEffect(() => {
    const subscription = methods.watch(async (value, {name}) => {
      if (name === 'noteTemplateVersionId') {
        session.updateEntity({
          noteTemplateVersionId: value.noteTemplateVersionId,
        });
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values: any) => {
    if (!values.note) {
      return;
    }
    await database.write(async () => {
      await database.get(Note.table).create(entity => {
        entity.partition = selectedGroup;
        entity.description = values.note;
        entity.entity = 'session';
        entity.entityId = session.id;
        entity.createdBy = userId;
      });
    });
    methods.setValue('note', '');
    dispatch(
      setNote({
        sessionId: session?.id,
        note: '',
      }),
    );
    Keyboard.dismiss();
  };

  return (
    <Drawer
      anchor={'right'}
      open={openNote}
      setOpen={setOpenNote}
      onClose={() => setOpenNote(!openNote)}>
      <SafeAreaView style={[styles.flex, styles.backgroundColorWhite]}>
        <FormProvider {...methods}>
          <View
            style={[
              styles.container,
              styles.alignCenter,
              styles.justifySpaceBetween,
              styles.paddingHorizontal,
            ]}>
            <TouchableOpacity
              style={[styles.paddingLVertical, styles.row, styles.alignCenter]}
              onPress={() => setOpenNote(false)}>
              <Icon name={'arrow-back'} size={20} />
              <View
                style={[
                  styles.column,
                  styles.justifyCenter,
                  styles.paddingLLeft,
                ]}>
                <Text style={[styles.paddingSMTop, Typography.P1]}>
                  {translations('return_to_session')}
                </Text>
              </View>
            </TouchableOpacity>
            <View
              style={[
                styles.height76,
                !isSmallSize
                  ? [styles.alignSelfEnd, styles.paddingLVertical]
                  : [],
                styles.menuMinWidth,
              ]}>
              <NoteTemplateVersion />
            </View>
          </View>
          <ScrollView contentContainerStyle={[styles.affixPadding]}>
            <View style={[styles.paddingHorizontal]}>
              <HookFormInput
                disabled={true}
                numberOfLines={3}
                label={'Automated Template'}
                name={'templateText'}
                showHelper={false}
              />
            </View>
            <FlatList
              style={[styles.padding]}
              keyExtractor={item => item.id}
              data={notes}
              renderItem={({item}) => (
                <View style={[styles.paddingSMVertical]}>
                  <SessionNoteListItem
                    item={item}
                    showAuthorsAndTimestamps={true}
                    setEditingNote={setEditingNote}
                  />
                </View>
              )}
            />
          </ScrollView>
          <View
            style={[
              styles.padding,
              styles.width,
              isWeb ? styles.maxHeight50 : styles.maxHeight25,
              /* eslint-disable-next-line react-native/no-inline-styles */
              {
                marginBottom: isWeb ? 0 : editingNote ? 0 : keyboardHeight,
              },
            ]}>
            <HookFormInput
              name={'note'}
              label={'Add Note'}
              onSubmit={methods.handleSubmit(handleSubmit)}
              numberOfLines={5}
              multiline={true}
              right={
                <TextInput.Icon
                  icon="send"
                  onPress={methods.handleSubmit(handleSubmit)}
                />
              }
              onBlur={() => {
                if (methods.getValues().note) {
                  dispatch(
                    setNote({
                      sessionId: session?.id,
                      note: methods.getValues().note,
                    }),
                  );
                }
              }}
              helper={
                'Note will only be saved after pressing the Submit button'
              }
            />
          </View>
        </FormProvider>
      </SafeAreaView>
    </Drawer>
  );
};

export default compose(
  withDatabase,
  withObservables(['session'], ({session}: any) => ({
    notes: session.activeNotes,
    templateVersion: session.noteTemplateVersion,
    appointment: session.appointment,
  })),
)(SessionNote);
