import React, {useState} from 'react';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {Q} from '@nozbe/watermelondb';
import {useStyle} from 'src/providers/style';
import withState from 'src/redux/wrapper';
import {mergeMap} from 'rxjs';
import {IconButton} from 'src/design-system';
import {appointmentTypes} from 'src/form-inputs/appointment-type-input';
import {cptCodes} from 'src/common-utils/cptCodes';
import moment from 'moment';
import {startOfDay, endOfDay, startOfMonth, endOfMonth} from 'date-fns';
import {rrulestr} from 'rrule';
import {exportCsv} from 'src/common-utils/export-csv';

import {Appointment, Payer} from 'src/models';
import {Text, View} from 'react-native';

import {Colors, Typography} from 'src/styles';
import _ from 'lodash';
import ExportFilterModal from 'src/modules/programs/components/export-filter-modal';

interface Props {
  patients: any;
}

const AuthExportTab = ({patients}: Props) => {
  const database = useDatabase();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [, setTotalUnits] = useState<undefined | number>(undefined);
  const [filters, setFilters] = useState<any>({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    allTime: [],
    clients: [],
  });
  const styles = useStyle();
  const [items] = useState([]);

  const toCsv = (content: string[][]) => {
    const rowString = content
      .map((d: any) => {
        return (
          _.values(d)
            .map(String)
            .map(v => v.replaceAll('"', '""'))
            .map(v => `"${v}"`)
            .join(',') + '\n'
        );
      })
      .join('');
    return `${rowString}`;
  };

  const initiateCsvExport = async () => {
    setLoading(true);
    let _exportData = null;

    //calculate
    _exportData = [
      [
        'Client',
        'Authorization',
        'Payer',
        'Start Date',
        'End Date',
        'Billing Code(s)',
        'Authorized Units',
        'Used Units',
        'Remaining Units',
      ],
    ];
    for (const patient of patients) {
      const authorizations = await database
        .get('authorizations')
        .query(
          Q.where('patient_id', patient.id),
          Q.where('deleted_at', Q.eq(null)),
          Q.or(
            Q.and(
              Q.where('start_date', Q.lte(filters.endDate.getTime())),
              Q.where('end_date', Q.gte(filters.startDate.getTime())),
            ),
            Q.and(
              Q.where('start_date', Q.gte(filters.startDate.getTime())),
              Q.where('start_date', Q.lte(filters.endDate.getTime())),
            ),
          ),
        );

      if (authorizations.length > 0) {
        for (const authorizationItem of authorizations) {
          let aggregate = 0;
          let authItemTracker = 0;
          for (const cptCode of cptCodes) {
            aggregate += parseInt(authorizationItem[cptCode], 10);
          }
          setTotalUnits(aggregate);

          const completedUnitsObj = {};
          const scheduledUnitsObj = {};
          let numberOfCompletedRecurringAppointments = 0;
          const appointments = await database
            .get(Appointment.table)
            .query(
              Q.on('participants', 'patient_id', patient?.id),
              Q.where('deleted_at', null),
            );
          for (const appointment of appointments) {
            const sessions = await appointment.session;
            if (
              !appointment.rrule &&
              !sessions.length &&
              moment(appointment.date).isBetween(
                startOfDay(authorizationItem.startDate),
                endOfDay(authorizationItem.endDate),
              )
            ) {
              const appointmentParticipants =
                await appointment.staffParticipants;

              for (const appointmentParticipant of appointmentParticipants) {
                if (appointmentParticipant.cpt.length) {
                  const scheduledSessionStartTimestamp = appointment?.start;
                  const scheduledSessionEndTimestamp = appointment?.end;

                  const scheduledSessionDurationInMins = moment(
                    scheduledSessionEndTimestamp,
                  ).diff(scheduledSessionStartTimestamp, 'minutes');

                  const scheduledSessionUnits =
                    appointmentParticipant?.cpt.length &&
                    appointmentParticipant?.cpt.length === 1 &&
                    appointmentParticipant?.cpt[0] === 'H2012'
                      ? Math.floor(scheduledSessionDurationInMins / 60)
                      : Math.round(scheduledSessionDurationInMins / 15);

                  appointmentParticipant.cpt.forEach(
                    (participantCptCode: string) => {
                      if (scheduledUnitsObj[participantCptCode]) {
                        scheduledUnitsObj[participantCptCode] +=
                          scheduledSessionUnits;
                      } else {
                        scheduledUnitsObj[participantCptCode] =
                          scheduledSessionUnits;
                      }
                    },
                  );
                }
              }
            }
            for (const session of sessions) {
              if (
                moment(session.date).isBetween(
                  startOfDay(authorizationItem.startDate),
                  endOfDay(authorizationItem.endDate),
                )
              ) {
                if (!appointment.rrule) {
                  // not deleted
                  if (!appointment.cancellationReason) {
                    // completed
                    if (session?.submissionTimestamp) {
                      if (session?.cpt?.length) {
                        const startTimestamp =
                          session?.editedStartTimestamp ||
                          session?.startTimestamp;
                        const endTimestamp =
                          session?.editedEndTimestamp || session?.endTimestamp;

                        const durationInMins = moment(endTimestamp).diff(
                          startTimestamp,
                          'minutes',
                        );

                        const units =
                          session?.cpt.length &&
                          session?.cpt.length === 1 &&
                          session?.cpt[0] === 'H2012'
                            ? Math.floor(durationInMins / 60)
                            : Math.round(durationInMins / 15);

                        session?.cpt?.forEach((sessionCode: string) => {
                          if (completedUnitsObj[sessionCode]) {
                            completedUnitsObj[sessionCode] += units;
                          } else {
                            completedUnitsObj[sessionCode] = units;
                          }
                        });
                      }
                      // not started
                    } else if (!session?.startTimestamp) {
                      const appointmentParticipants =
                        await appointment.staffParticipants;

                      for (const appointmentParticipant of appointmentParticipants) {
                        if (appointmentParticipant.cpt.length) {
                          const scheduledSessionStartTimestamp =
                            appointment?.start;
                          const scheduledSessionEndTimestamp = appointment?.end;

                          const scheduledSessionDurationInMins = moment(
                            scheduledSessionEndTimestamp,
                          ).diff(scheduledSessionStartTimestamp, 'minutes');

                          const scheduledSessionUnits =
                            appointmentParticipant?.cpt.length &&
                            appointmentParticipant?.cpt.length === 1 &&
                            appointmentParticipant?.cpt[0] === 'H2012'
                              ? Math.floor(scheduledSessionDurationInMins / 60)
                              : Math.round(scheduledSessionDurationInMins / 15);

                          appointmentParticipant.cpt.forEach(
                            (participantCptCode: string) => {
                              if (scheduledUnitsObj[participantCptCode]) {
                                scheduledUnitsObj[participantCptCode] +=
                                  scheduledSessionUnits;
                              } else {
                                scheduledUnitsObj[participantCptCode] =
                                  scheduledSessionUnits;
                              }
                            },
                          );
                        }
                      }
                    }
                  }
                  // recurring
                } else {
                  // completed
                  if (session?.submissionTimestamp) {
                    numberOfCompletedRecurringAppointments++;
                    if (session?.cpt?.length) {
                      const startTimestamp =
                        session?.editedStartTimestamp ||
                        session?.startTimestamp;
                      const endTimestamp =
                        session?.editedEndTimestamp || session?.endTimestamp;

                      const durationInMins = moment(endTimestamp).diff(
                        startTimestamp,
                        'minutes',
                      );

                      const units =
                        session?.cpt.length &&
                        session?.cpt.length === 1 &&
                        session?.cpt[0] === 'H2012'
                          ? Math.floor(durationInMins / 60)
                          : Math.round(durationInMins / 15);

                      session?.cpt?.forEach((sessionCode: string) => {
                        if (completedUnitsObj[sessionCode]) {
                          completedUnitsObj[sessionCode] += units;
                        } else {
                          completedUnitsObj[sessionCode] = units;
                        }
                      });
                    }
                  }
                }
              }
            }
          }

          for (const appointment of appointments) {
            const sessions = await appointment.session;
            const appointmentParticipants = await appointment.staffParticipants;
            if (appointment.rrule) {
              const rruleSet = rrulestr(appointment.rrule, {
                forceset: true,
              });

              const numberOfOccurrences =
                rruleSet.between(
                  startOfDay(authorizationItem.startDate),
                  endOfDay(authorizationItem.endDate),
                ).length - numberOfCompletedRecurringAppointments;

              if (!sessions.length) {
                for (const appointmentParticipant of appointmentParticipants) {
                  if (appointmentParticipant.cpt.length) {
                    const scheduledSessionStartTimestamp = appointment?.start;
                    const scheduledSessionEndTimestamp = appointment?.end;

                    const scheduledSessionDurationInMins = moment(
                      scheduledSessionEndTimestamp,
                    ).diff(scheduledSessionStartTimestamp, 'minutes');

                    const scheduledSessionUnits =
                      appointmentParticipant?.cpt.length &&
                      appointmentParticipant?.cpt.length === 1 &&
                      appointmentParticipant?.cpt[0] === 'H2012'
                        ? Math.floor(scheduledSessionDurationInMins / 60)
                        : Math.round(scheduledSessionDurationInMins / 15);

                    _.times(numberOfOccurrences, () => {
                      appointmentParticipant.cpt.forEach(
                        (participantCptCode: string) => {
                          if (scheduledUnitsObj[participantCptCode]) {
                            scheduledUnitsObj[participantCptCode] +=
                              scheduledSessionUnits;
                          } else {
                            scheduledUnitsObj[participantCptCode] =
                              scheduledSessionUnits;
                          }
                        },
                      );
                    });
                  }
                }
              }
            }

            for (const session of sessions) {
              if (session?.createdBy !== 'System') {
                if (
                  moment(session.date).isBetween(
                    startOfDay(authorizationItem.startDate),
                    endOfDay(authorizationItem.endDate),
                  )
                ) {
                  // recurring appointments that have not started
                  if (appointment.rrule && !session?.startTimestamp) {
                    const rruleSet = rrulestr(appointment.rrule, {
                      forceset: true,
                    });

                    const numberOfOccurrences =
                      rruleSet.between(
                        startOfDay(authorizationItem.startDate),
                        endOfDay(authorizationItem.endDate),
                      ).length - numberOfCompletedRecurringAppointments;

                    for (const appointmentParticipant of appointmentParticipants) {
                      if (appointmentParticipant.cpt.length) {
                        const scheduledSessionStartTimestamp =
                          appointment?.start;
                        const scheduledSessionEndTimestamp = appointment?.end;

                        const scheduledSessionDurationInMins = moment(
                          scheduledSessionEndTimestamp,
                        ).diff(scheduledSessionStartTimestamp, 'minutes');

                        const scheduledSessionUnits =
                          appointmentParticipant?.cpt.length &&
                          appointmentParticipant?.cpt.length === 1 &&
                          appointmentParticipant?.cpt[0] === 'H2012'
                            ? Math.floor(scheduledSessionDurationInMins / 60)
                            : Math.round(scheduledSessionDurationInMins / 15);

                        _.times(numberOfOccurrences, () => {
                          appointmentParticipant.cpt.forEach(
                            (participantCptCode: string) => {
                              if (scheduledUnitsObj[participantCptCode]) {
                                scheduledUnitsObj[participantCptCode] +=
                                  scheduledSessionUnits;
                              } else {
                                scheduledUnitsObj[participantCptCode] =
                                  scheduledSessionUnits;
                              }
                            },
                          );
                        });
                      }
                    }
                  }
                }
              }
            }
          }

          //Map CPT codes
          const payer = authorizationItem?.payerId
            ? await database.get(Payer.table).find(authorizationItem?.payerId)
            : '';

          const payerName = payer?.name ? payer?.name : '';
          cptCodes.map(code => {
            const cptNumString = code.replace('cpt', '');
            const remainingUnits =
              authorizationItem[code] -
                (completedUnitsObj[cptNumString] || 0) || 0;
            const completedUnitsString = completedUnitsObj[cptNumString] || '0';
            if (authorizationItem[code]) {
              let label =
                appointmentTypes.find(
                  appointmentType =>
                    appointmentType.label.indexOf(code.replace('cpt', '')) !==
                    -1,
                ).label || null;

              if (authItemTracker === 0) {
                _exportData.push([
                  `${patient.firstName} ${patient.lastName}`,
                  authorizationItem?.authorizationNumber,
                  payerName,
                  `${moment(authorizationItem?.startDate).format('MM/DD/YY')}`,
                  `${moment(authorizationItem?.endDate).format('MM/DD/YY')}`,
                  label,
                  `${authorizationItem[code]}`,
                  completedUnitsString,
                  remainingUnits,
                ]);
                authItemTracker = 1;
              } else {
                _exportData.push([
                  '',
                  '',
                  '',
                  '',
                  '',
                  label,
                  `${authorizationItem[code]}`,
                  completedUnitsString,
                  remainingUnits,
                ]);
              }
            }
          });
        }

        toCsv(_exportData);
      }
    }
    await exportCsv('authorizations', _exportData);
    setLoading(false);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  return (
    <>
      <View>
        <View style={[styles.row, styles.justifySpaceBetween, styles.padding]}>
          <View>
            <Text
              style={[
                Typography.H1,
                styles.row,
                styles.textAlignCenter,
                styles.paddingTop,
                styles.textColorPrimary,
              ]}>
              Authorizations
            </Text>
          </View>
          <View
            style={[
              styles.flex,
              styles.row,
              styles.justifyEnd,
              styles.alignSelfCenter,
            ]}>
            <View style={[styles.paddingLRight]}>
              <IconButton
                loading={loading}
                disabled={loading || success}
                icon={success ? 'check' : 'download'}
                type={'outlined'}
                color={success ? Colors.SUCCESS : Colors.RAVEN_BLACK}
                onPress={() => initiateCsvExport(items)}
              />
            </View>
            <ExportFilterModal applyFilters={setFilters} filters={filters} />
          </View>
        </View>
        <View
          style={[styles.row, styles.justifySpaceBetween, styles.paddingLeft]}>
          <View>
            <Text style={[Typography.P2, styles.row, styles.textAlignCenter]}>
              Export all client authorizations across selected date range.
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables([], ({database, authentication}: any) => {
    return {
      instance: database
        ?.get('instances')
        .query(Q.where('_partition', authentication.selectedGroup))
        .observe()
        .pipe(mergeMap(x => x)),
    };
  }),
  withObservables(['instance'], ({database, authentication}: any) => ({
    patients: database
      .get('patients')
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
      ),
  })),
)(AuthExportTab);
