import React, {useCallback, useEffect, useState} from 'react';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import NoResults from 'src/common-components/noResults';
import HistorySessionItem from 'src/modules/patients/components/history-session-item';
import {of} from 'rxjs';
import {startOfDay} from 'date-fns';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {rrulestr} from 'rrule';
import moment from 'moment';

const StaffUpcomingSessions = ({
  sessions,
  filters,
  setExportItems,
  role,
  profile,
  deleteCallback,
  user,
}: any) => {
  const database = useDatabase();
  const [items, setItems] = useState<any[]>([]);
  const recalculateAppointments = async () => {
    const locationQuery = [];
    if (filters?.locations && filters.locations.length) {
      locationQuery.push(Q.where('location', Q.oneOf(filters.locations)));
    }

    const clients = [];
    for (const client of filters.patients) {
      clients.push(client);
    }

    let clientQueries: [Q.On[]] | any[] = [];
    if (clients.length) {
      clientQueries = [Q.where('patient_id', Q.oneOf(clients))];
    } else {
      clientQueries = [];
    }

    let billingCodeQueries = [];
    if (filters?.types && filters.types.length) {
      const types = [];
      for (const code of filters.types) {
        types.push(Q.where('cpt', Q.includes(code)));
      }
      billingCodeQueries.push(Q.or(types));
    }

    const statusQuery = [];
    if (filters?.status && filters.status.length) {
      const statuses = [];
      for (const status of filters.status) {
        if (status === 'not-started') {
          statuses.push(Q.where('start_timestamp', Q.eq(null)));
        } else if (status === 'in-progress') {
          statuses.push(
            Q.and(
              Q.where('start_timestamp', Q.notEq(null)),
              Q.where('end_timestamp', Q.eq(null)),
            ),
          );
        } else if (status === 'missing-values') {
          statuses.push(
            Q.and(
              Q.where('end_timestamp', Q.notEq(null)),
              Q.where('submission_timestamp', Q.eq(null)),
            ),
          );
        } else if (status === 'completed') {
          statuses.push(Q.where('submission_timestamp', Q.notEq(null)));
        }
      }
      statusQuery.push(Q.or(...statuses));
    }

    const queriedAppointments = await database
      .get('appointments')
      .query(
        Q.where('_partition', profile?.partition),
        Q.or(
          Q.and(
            Q.where('rrule', ''),
            Q.where('date', Q.gt(startOfDay(filters.startDate).getTime())),
            Q.where('date', Q.lt(filters.endDate.getTime())),
            Q.where('deleted_at', null),
          ),
          Q.and(
            Q.where('rrule', Q.notEq('')),
            Q.where('deleted_at', null),
            Q.or(
              Q.and(
                Q.where(
                  'start_timestamp',
                  Q.gt(startOfDay(filters.startDate).getTime()),
                ),
                Q.where('start_timestamp', Q.lt(filters.endDate.getTime())),
              ),
              Q.and(
                Q.where(
                  'start_timestamp',
                  Q.lt(startOfDay(filters.startDate).getTime()),
                ),
                Q.or(
                  Q.where('end_timestamp', Q.eq(0)),
                  Q.where(
                    'end_timestamp',
                    Q.gt(startOfDay(filters.startDate).getTime()),
                  ),
                ),
              ),
            ),
          ),
        ),
        Q.on(
          'participants',
          Q.and(
            Q.where('user_id', Q.eq(user.id)),
            Q.where('deleted_at', null),
            ...billingCodeQueries,
          ),
        ),
        ...locationQuery,
      );
    const apptIDs = [];

    for (const appointment of queriedAppointments) {
      if (appointment.rrule) {
        const rruleSet = rrulestr(appointment.rrule, {
          forceset: true,
        });

        for (const date of rruleSet
          .between(startOfDay(filters.startDate), filters.endDate)
          .map(rruleDate => {
            const realDate = new Date(
              rruleDate.getFullYear(),
              rruleDate.getMonth(),
              rruleDate.getDate(),
            );
            realDate.setTime(
              rruleDate.getTime() +
                rruleSet.options.dtstart.getTimezoneOffset() * 60 * 1000,
            );
            return realDate;
          })) {
          const exdateFind = rruleSet
            .exdates()
            .findIndex(
              (exdate: any) =>
                moment(exdate).format('MMM DD YYYY') ===
                moment(date).format('MMM DD YYYY'),
            );
          if (exdateFind !== -1) {
            continue;
          }
          apptIDs.push(appointment.id);
        }
      } else {
        apptIDs.push(appointment.id);
      }
    }
    let sessionsQueried: any[] = [];
    if (apptIDs.length > 0) {
      sessionsQueried = await database
        .get('sessions')
        .query(
          Q.where('appointment_id', Q.oneOf(apptIDs)),
          Q.and(
            Q.where('date', Q.gt(startOfDay(filters.startDate).getTime())),
            Q.where('date', Q.lt(filters.endDate.getTime())),
            Q.where('deleted_at', Q.eq(null)),
          ),
          ...clientQueries,
          ...statusQuery,
        );
    }
    const sessionItems = sessionsQueried.length
      ? sessionsQueried.sort((a, b) => b.date - a.date)
      : [];

    setItems(sessionItems);
    setExportItems(sessionItems);
  };

  useEffect(() => {
    recalculateAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.startDate,
    filters.endDate,
    sessions,
    filters.locations,
    filters.types,
    filters.patients,
    filters.status,
  ]);

  const renderItem = useCallback(
    item => {
      return (
        <HistorySessionItem
          key={item.id}
          showStaffParticipant={true}
          session={item}
          canEdit={role?.staffHistoryEdit}
          canDelete={role?.staffHistoryDelete}
          deleteCallback={deleteCallback}
          profile={profile}
          isSupervision={item.type === 'supervision'}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      {items.length > 0 ? (
        <>{items.map(renderItem)}</>
      ) : (
        <NoResults iconName={'calendar'} message={'No Previous Sessions'} />
      )}
    </>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables([], ({authentication, database}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
)(StaffUpcomingSessions);
