import _ from 'lodash';
import moment from 'moment';

export const analyzePrograms = (
  program: any,
  sets: any,
  events: any,
  programSessions: any,
  sessionMessage = false,
  session: any,
) => {
  const analysisState: any = [];
  const analysis: any = {};
  const sessionSets = _.groupBy(sets, 'session.id');
  const eventsBySetId = _.groupBy(events, 'set.id');
  switch (program?.method) {
    case 'task_analysis':
      for (const sessionId of Object.keys(sessionSets)) {
        let collectorIds: string[] = [];
        const sessionSet = sessionSets[sessionId];
        const average = Math.ceil(
          sessionSet.reduce((totalSum: number, set: any) => {
            const setEvents = eventsBySetId[set?.id];
            let eventCollectors = _.uniq(
              _.map(setEvents, (event: any) => event?.createdBy),
            ).filter((id: string) => id !== '');
            collectorIds.push(...eventCollectors);
            return (
              totalSum +
              Math.ceil(
                ((setEvents?.filter((event: any) => event.value === 'success')
                  .length || 0) /
                  (setEvents?.length || 1)) *
                  100,
              )
            );
          }, 0) / sessionSet.length,
        );
        collectorIds = _.uniq(collectorIds);

        let state;
        let consecutiveSessions = false;
        if (analysisState.length === 0) {
          if (program?.baseline) {
            if (
              program?.baselineSessions === 1 &&
              average >= program?.baselineProbes
            ) {
              state = 'completed';
            } else {
              state = 'baseline';
            }
          } else {
            if (
              average >= program?.masteryValue &&
              program?.masterySessions === 1 &&
              !program?.masteryStaff
            ) {
              state = 'completed';
            } else {
              state = 'in-treatment';
            }
          }
        } else if (
          analysisState[analysisState.length - 1]?.state === 'baseline'
        ) {
          if (analysisState.length === program?.baselineSessions) {
            if (
              program?.masterySessions === 1 &&
              !program?.masteryStaff &&
              average >= program?.masteryValue
            ) {
              state = 'completed';
            } else {
              state = 'in-treatment';
            }
          } else if (analysisState.length + 1 === program?.baselineSessions) {
            for (let i = analysisState.length - 1; i >= 0; i--) {
              if (analysisState[i].average < program?.baselineProbes) {
                break;
              }

              if (analysisState.length - i >= program?.baselineSessions - 1) {
                consecutiveSessions = true;
                break;
              }
            }
            if (
              consecutiveSessions ||
              (program?.masterySessions === 1 &&
                average >= program?.masteryValue &&
                !program.masteryStaff)
            ) {
              state = 'completed';
            } else {
              state = 'in-treatment';
            }
          } else {
            state = 'baseline';
          }
        } else if (
          analysisState[analysisState.length - 1]?.state === 'in-treatment'
        ) {
          let masterySessions = program?.masterySessions;
          let completedSessions = 0;
          if (program?.masteryStaff) {
            masterySessions =
              program?.masterySessions * program?.masteryStaffMinimum;
          }
          for (let i = analysisState.length - 1; i >= 0; i--) {
            if (analysisState[i].state !== 'in-treatment') {
              break;
            } else {
              completedSessions = completedSessions + 1;
            }
          }

          if (completedSessions + 1 >= masterySessions) {
            consecutiveSessions = false;
            let staffMembers: any[] = [];
            if (average < program?.masteryValue) {
              state = 'in-treatment';
            } else {
              if (program?.masteryStaff) {
                for (let i = analysisState.length - 1; i >= 0; i--) {
                  if (
                    !staffMembers.some(user =>
                      analysisState[i]?.collectors.includes(user),
                    )
                  ) {
                    analysisState[i].collectors?.forEach((element: any) => {
                      staffMembers.push(element);
                    });
                  }
                }

                if (!staffMembers.some(user => collectorIds.includes(user))) {
                  collectorIds?.forEach(collector => {
                    staffMembers.push(collector);
                  });
                }
                if (staffMembers.length >= program?.masteryStaffMinimum) {
                  let staffMasteredArray: any[] = [];
                  for (const staff of staffMembers) {
                    let staffAnalysis = analysisState.filter(element =>
                      element.collectors.includes(staff),
                    );
                    for (let i = staffAnalysis.length - 1; i >= 0; i--) {
                      if (staffAnalysis[i].state !== 'in-treatment') {
                        break;
                      }
                      if (staffAnalysis[i].average < program?.masteryValue) {
                        break;
                      }

                      if (
                        staffAnalysis.length - i >=
                        program?.masterySessions
                      ) {
                        staffMasteredArray.push(staff);
                        break;
                      }
                    }
                  }

                  if (
                    staffMasteredArray.length >= program?.masteryStaffMinimum
                  ) {
                    state = 'completed';
                  } else if (
                    staffMasteredArray.length + 1 ===
                    program?.masteryStaffMinimum
                  ) {
                    //check if current state passes vibe check
                    const currentStaffAnalysis = analysisState.filter(element =>
                      element.collectors.includes(collectorIds[0]),
                    );

                    if (
                      !staffMasteredArray.includes(collectorIds[0]) &&
                      currentStaffAnalysis.length + 1 ===
                        program?.masterySessions
                    ) {
                      state = 'completed';
                    } else {
                      state = 'in-treatment';
                    }
                  } else {
                    state = 'in-treatment';
                  }
                } else {
                  state = 'in-treatment';
                }
              } else {
                let consecutiveSessionCount = 0;
                for (let i = analysisState.length - 1; i >= 0; i--) {
                  if (analysisState[i].state !== 'in-treatment') {
                    break;
                  }
                  if (analysisState[i].average < program?.masteryValue) {
                    break;
                  } else {
                    consecutiveSessionCount = consecutiveSessionCount + 1;
                  }
                }
                if (consecutiveSessionCount + 1 >= program?.masterySessions) {
                  state = 'completed';
                } else {
                  state = 'in-treatment';
                }
              }
            }
          } else {
            state = 'in-treatment';
          }
        } else if (
          analysisState[analysisState.length - 1]?.state === 'in-maintenance'
        ) {
          let allowance = 0;
          consecutiveSessions = false;
          for (let i = analysisState.length - 1; i >= 0; i--) {
            if (analysisState[i].state !== 'in-maintenance') {
              break;
            }
            if (analysisState[i].average < program?.maintenanceValue) {
              allowance = allowance + 1;
            }

            if (analysisState.length - i >= program?.maintenanceSessions) {
              consecutiveSessions = true;
              break;
            }
          }
          if (
            consecutiveSessions &&
            allowance <= program?.maintenanceAllowance
          ) {
            state = 'completed';
          } else if (
            consecutiveSessions &&
            allowance >= program?.maintenanceAllowance
          ) {
            state = 'in-treatment';
          } else {
            state = 'in-maintenance';
          }
        } else if (
          analysisState[analysisState.length - 1]?.state === 'completed'
        ) {
          if (program?.maintenance) {
            state = 'in-maintenance';
            switch (program?.maintenanceCadence) {
              case 'daily':
                state = 'in-maintenance';
                break;
              case 'weekly':
                const days = [
                  'Sunday',
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                ];
                const dayNum = new Date(
                  analysisState[analysisState.length - 1].timestamp,
                ).getDay();
                const day = days[dayNum];
                if (day === program?.maintenanceCadenceWeekly) {
                  state = 'in-maintenance';
                } else {
                  state = 'completed';
                }
                break;
              case 'monthly':
                const date = new Date(
                  analysisState[analysisState.length - 1].timestamp,
                ).getDate();
                if (date === program?.maintenanceCadenceMonthly) {
                  state = 'in-maintenance';
                } else {
                  state = 'completed';
                }
                break;
            }
            if (
              state === 'in-maintenance' &&
              program?.maintenanceAllowance === 0 &&
              average < program?.maintenanceValue &&
              (program?.maintenanceSessions === 1 ||
                program?.maintenanceCadence === 'daily')
            ) {
              state = 'in-treatment';
            }
          } else {
            state = 'completed';
          }
        } else {
          state = 'in-treatment';
        }

        const sessionObject = {
          sessionId,
          average,
          timestamp: sessionSet[0]?.createdAt,
          collectors: collectorIds,
          state,
        };

        analysisState.push(sessionObject);
        analysis[sessionId] = sessionObject;
      }
      break;
    case 'interval':
      for (const {id: sessionId} of programSessions) {
        let collectorIds: string[] = [];
        const sessionSet = sessionSets[sessionId];
        const average =
          sessionSet.reduce((a: number, b: Set) => {
            const setEvents = eventsBySetId[b.id] || [];
            let eventCollectors = _.uniq(
              _.map(setEvents, (event: any) => event?.createdBy),
            );
            collectorIds.push(...eventCollectors);
            return (
              a +
              setEvents.reduce((setPercentage: number, event: any) => {
                return setPercentage + (event.value === 'Y' ? 1 : 0) * 100;
              }, 0) /
                (setEvents.length || 1)
            );
          }, 0) / sessionSet.length;
        collectorIds = _.uniq(collectorIds).filter((id: string) => id !== '');

        let state;
        let consecutiveSessions = false;
        if (analysisState.length === 0) {
          if (program?.baseline) {
            if (
              program?.baselineSessions === 1 &&
              ((average >= program?.baselineProbes &&
                program?.adjustment === 'increase') ||
                (average <= program?.baselineProbes &&
                  program?.adjustment === 'decrease'))
            ) {
              state = 'completed';
            } else {
              state = 'baseline';
            }
          } else {
            if (
              ((average >= program?.masteryProbes &&
                program?.adjustment === 'increase') ||
                (average <= program?.masteryProbes &&
                  program?.adjustment === 'decrease')) &&
              program?.masterySessions === 1 &&
              !program?.masteryStaff
            ) {
              state = 'completed';
            } else {
              state = 'in-treatment';
            }
          }
        } else if (
          analysisState[analysisState.length - 1]?.state === 'baseline'
        ) {
          if (analysisState.length === program?.baselineSessions) {
            if (
              program?.masterySessions === 1 &&
              !program?.masteryStaff &&
              ((average >= program?.masteryValue &&
                program?.adjustment === 'increase') ||
                (average <= program?.masteryValue &&
                  program?.adjustment === 'decrease'))
            ) {
              state = 'completed';
            } else {
              state = 'in-treatment';
            }
          } else if (analysisState.length + 1 === program?.baselineSessions) {
            for (let i = analysisState.length - 1; i >= 0; i--) {
              if (
                (analysisState[i].average > program?.baselineProbes &&
                  program.adjustment === 'decrease') ||
                (analysisState[i].average < program?.baselineProbes &&
                  program?.adjustment === 'increase')
              ) {
                break;
              }

              if (analysisState.length - i >= program?.baselineSessions - 1) {
                consecutiveSessions = true;
                break;
              }
            }
            if (
              consecutiveSessions ||
              (program?.masterySessions === 1 &&
                ((average >= program?.masteryValue &&
                  program?.adjustment === 'increase') ||
                  (average <= program?.masteryValue &&
                    program?.adjustment === 'decrease')) &&
                !program.masteryStaff)
            ) {
              state = 'completed';
            } else {
              state = 'in-treatment';
            }
          } else {
            state = 'baseline';
          }
        } else if (
          analysisState[analysisState.length - 1]?.state === 'in-treatment'
        ) {
          let masterySessions = program?.masterySessions;
          let completedSessions = 0;
          if (program?.masteryStaff) {
            masterySessions =
              program?.masterySessions * program?.masteryStaffMinimum;
          }
          for (let i = analysisState.length - 1; i >= 0; i--) {
            if (analysisState[i].state !== 'in-treatment') {
              break;
            } else {
              completedSessions = completedSessions + 1;
            }
          }

          if (completedSessions + 1 >= masterySessions) {
            consecutiveSessions = false;
            let staffMembers: any[] = [];
            if (
              (average < program?.masteryValue &&
                program?.adjustment === 'increase') ||
              (average > program?.masteryValue &&
                program?.adjustment === 'decrease')
            ) {
              state = 'in-treatment';
            } else {
              if (program?.masteryStaff) {
                for (let i = analysisState.length - 1; i >= 0; i--) {
                  if (
                    !staffMembers.some(user =>
                      analysisState[i]?.collectors.includes(user),
                    )
                  ) {
                    analysisState[i].collectors?.forEach((element: any) => {
                      staffMembers.push(element);
                    });
                  }
                }

                if (!staffMembers.some(user => collectorIds.includes(user))) {
                  collectorIds?.forEach(collector => {
                    staffMembers.push(collector);
                  });
                }
                if (staffMembers.length >= program?.masteryStaffMinimum) {
                  let staffMasteredArray: any[] = [];
                  for (const staff of staffMembers) {
                    let staffAnalysis = analysisState.filter(element =>
                      element.collectors.includes(staff),
                    );
                    for (let i = staffAnalysis.length - 1; i >= 0; i--) {
                      if (staffAnalysis[i].state !== 'in-treatment') {
                        break;
                      }
                      if (
                        (staffAnalysis[i].average > program?.masteryValue &&
                          program?.adjustment === 'decrease') ||
                        (staffAnalysis[i].average < program?.masteryValue &&
                          program?.adjustment === 'increase')
                      ) {
                        break;
                      }

                      if (
                        staffAnalysis.length - i >=
                        program?.masterySessions
                      ) {
                        staffMasteredArray.push(staff);
                        break;
                      }
                    }
                  }

                  if (
                    staffMasteredArray.length >= program?.masteryStaffMinimum
                  ) {
                    state = 'completed';
                  } else if (
                    staffMasteredArray.length + 1 ===
                    program?.masteryStaffMinimum
                  ) {
                    //check if current state passes vibe check
                    const currentStaffAnalysis = analysisState.filter(element =>
                      element.collectors.includes(collectorIds[0]),
                    );

                    if (
                      !staffMasteredArray.includes(collectorIds[0]) &&
                      currentStaffAnalysis.length + 1 ===
                        program?.masterySessions
                    ) {
                      state = 'completed';
                    } else {
                      state = 'in-treatment';
                    }
                  } else {
                    state = 'in-treatment';
                  }
                } else {
                  state = 'in-treatment';
                }
              } else {
                let consecutiveSessionCount = 0;
                for (let i = analysisState.length - 1; i >= 0; i--) {
                  if (analysisState[i].state !== 'in-treatment') {
                    break;
                  }
                  if (
                    (analysisState[i].average > program?.masteryValue &&
                      program?.adjustment === 'decrease') ||
                    (analysisState[i].average < program?.masteryValue &&
                      program?.adjustment === 'increase')
                  ) {
                    break;
                  } else {
                    consecutiveSessionCount = consecutiveSessionCount + 1;
                  }
                }
                if (consecutiveSessionCount + 1 >= program?.masterySessions) {
                  state = 'completed';
                } else {
                  state = 'in-treatment';
                }
              }
            }
          } else {
            state = 'in-treatment';
          }
        } else if (
          analysisState[analysisState.length - 1]?.state === 'in-maintenance'
        ) {
          let allowance = 0;
          consecutiveSessions = false;
          for (let i = analysisState.length - 1; i >= 0; i--) {
            if (analysisState[i].state !== 'in-maintenance') {
              break;
            }
            if (
              (analysisState[i].average > program?.maintenanceValue &&
                program?.adjustment === 'decrease') ||
              (analysisState[i].average < program?.maintenanceValue &&
                program?.adjustment === 'increase')
            ) {
              allowance = allowance + 1;
            }

            if (analysisState.length - i >= program?.maintenanceSessions) {
              consecutiveSessions = true;
              break;
            }
          }
          if (
            consecutiveSessions &&
            allowance <= program?.maintenanceAllowance
          ) {
            state = 'completed';
          } else if (
            consecutiveSessions &&
            allowance >= program?.maintenanceAllowance
          ) {
            state = 'in-treatment';
          } else {
            state = 'in-maintenance';
          }
        } else if (
          analysisState[analysisState.length - 1]?.state === 'completed'
        ) {
          if (program?.maintenance) {
            state = 'in-maintenance';
            switch (program?.maintenanceCadence) {
              case 'daily':
                state = 'in-maintenance';
                break;
              case 'weekly':
                const days = [
                  'Sunday',
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                ];
                const dayNum = new Date(
                  analysisState[analysisState.length - 1].timestamp,
                ).getDay();
                const day = days[dayNum];
                if (day === program?.maintenanceCadenceWeekly) {
                  state = 'in-maintenance';
                } else {
                  state = 'completed';
                }
                break;
              case 'monthly':
                const date = new Date(
                  analysisState[analysisState.length - 1].timestamp,
                ).getDate();
                if (date === program?.maintenanceCadenceMonthly) {
                  state = 'in-maintenance';
                } else {
                  state = 'completed';
                }
                break;
            }
          } else {
            state = 'completed';
          }
          if (
            state === 'in-maintenance' &&
            program?.maintenanceAllowance === 0 &&
            ((average < program?.maintenanceValue &&
              program?.adjustment === 'increase') ||
              (average > program?.maintenanceValue &&
                program?.adjustment === 'decrease')) &&
            (program?.maintenanceSessions === 1 ||
              program?.maintenanceCadence === 'daily')
          ) {
            state = 'in-treatment';
          }
        } else {
          state = 'in-treatment';
        }

        const sessionObject = {
          sessionId,
          average: average,
          timestamp: sessionSet[0]?.createdAt,
          collectors: collectorIds,
          state,
        };

        analysisState.push(sessionObject);
        analysis[sessionId] = sessionObject;
      }
      break;
    case 'rate':
      for (const {
        id: sessionId,
        startTimestamp,
        endTimestamp,
        userId,
      } of programSessions) {
        const set = sessionSets[sessionId]?.[0];
        const setEvents = eventsBySetId[set?.id];
        const rateEvents = setEvents?.length || 0;
        const setCollectorIds = _.uniq(
          _.map(setEvents, (event: any) => event?.createdBy),
        ).filter((id: string) => id !== '');
        const collectorIds = setCollectorIds.length
          ? setCollectorIds
          : [userId];
        const duration =
          sessionSets[sessionId]?.reduce((a: number, b: any) => {
            return (
              a +
              moment
                .duration(
                  moment(
                    !b.endTimestamp ? moment(endTimestamp) : b.endTimestamp,
                  ).diff(moment(b.startTimestamp)),
                )
                .asMinutes()
            );
          }, 0) || 0.001;

        let denominator = duration;

        switch (program?.unit) {
          case 'per_min':
            denominator = duration;
            break;
          case 'per_hour':
            denominator = duration / 60;
            break;
          case 'per_session':
            denominator = 1;
            break;
        }

        const rate = rateEvents / denominator;
        const baselineValue = program?.baselineProbes / denominator;
        const masteryValue = program?.masteryValue / denominator;
        const maintenanceValue = program?.maintenanceValue / denominator;
        let state;
        let consecutiveSessions = false;
        if (analysisState.length === 0) {
          if (program?.baseline) {
            if (
              program?.baselineSessions === 1 &&
              ((rate >= baselineValue && program?.adjustment === 'increase') ||
                (rate <= baselineValue && program?.adjustment === 'decrease'))
            ) {
              state = 'completed';
            } else {
              state = 'baseline';
            }
          } else {
            if (
              ((rate >= masteryValue && program?.adjustment === 'increase') ||
                (rate <= masteryValue && program?.adjustment === 'decrease')) &&
              program?.masterySessions === 1 &&
              !program?.masteryStaff
            ) {
              state = 'completed';
            } else {
              state = 'in-treatment';
            }
          }
        } else if (
          analysisState[analysisState.length - 1]?.state === 'baseline'
        ) {
          if (analysisState.length === program?.baselineSessions) {
            if (
              program?.masterySessions === 1 &&
              !program?.masteryStaff &&
              ((rate >= masteryValue && program?.adjustment === 'increase') ||
                (rate <= masteryValue && program?.adjustment === 'decrease'))
            ) {
              state = 'completed';
            } else {
              state = 'in-treatment';
            }
          } else if (analysisState.length + 1 === program?.baselineSessions) {
            for (let i = analysisState.length - 1; i >= 0; i--) {
              if (
                (analysisState[i].average > baselineValue &&
                  program.adjustment === 'decrease') ||
                (analysisState[i].average < baselineValue &&
                  program?.adjustment === 'increase')
              ) {
                break;
              }

              if (analysisState.length - i >= program?.baselineSessions - 1) {
                consecutiveSessions = true;
                break;
              }
            }
            if (
              consecutiveSessions ||
              (program?.masterySessions === 1 &&
                ((rate >= masteryValue && program?.adjustment === 'increase') ||
                  (rate <= masteryValue &&
                    program?.adjustment === 'decrease')) &&
                !program.masteryStaff)
            ) {
              state = 'completed';
            } else {
              state = 'in-treatment';
            }
          } else {
            state = 'baseline';
          }
        } else if (
          analysisState[analysisState.length - 1]?.state === 'in-treatment'
        ) {
          let masterySessions = program?.masterySessions;
          let completedSessions = 0;
          if (program?.masteryStaff) {
            masterySessions =
              program?.masterySessions * program?.masteryStaffMinimum;
          }
          for (let i = analysisState.length - 1; i >= 0; i--) {
            if (analysisState[i].state !== 'in-treatment') {
              break;
            } else {
              completedSessions = completedSessions + 1;
            }
          }

          if (completedSessions + 1 >= masterySessions) {
            consecutiveSessions = false;
            let staffMembers: any[] = [];
            if (
              (rate < masteryValue && program?.adjustment === 'increase') ||
              (rate > masteryValue && program?.adjustment === 'decrease')
            ) {
              state = 'in-treatment';
            } else {
              if (program?.masteryStaff) {
                for (let i = analysisState.length - 1; i >= 0; i--) {
                  if (
                    !staffMembers.some(user =>
                      analysisState[i]?.collectors.includes(user),
                    )
                  ) {
                    analysisState[i].collectors?.forEach((element: any) => {
                      staffMembers.push(element);
                    });
                  }
                }

                if (!staffMembers.some(user => collectorIds.includes(user))) {
                  collectorIds?.forEach(collector => {
                    staffMembers.push(collector);
                  });
                }
                if (staffMembers.length >= program?.masteryStaffMinimum) {
                  let staffMasteredArray: any[] = [];
                  for (const staff of staffMembers) {
                    let staffAnalysis = analysisState.filter(element =>
                      element.collectors.includes(staff),
                    );
                    for (let i = staffAnalysis.length - 1; i >= 0; i--) {
                      if (staffAnalysis[i].state !== 'in-treatment') {
                        break;
                      }
                      if (
                        (staffAnalysis[i].average > masteryValue &&
                          program?.adjustment === 'decrease') ||
                        (staffAnalysis[i].average < masteryValue &&
                          program?.adjustment === 'increase')
                      ) {
                        break;
                      }

                      if (
                        staffAnalysis.length - i >=
                        program?.masterySessions
                      ) {
                        staffMasteredArray.push(staff);
                        break;
                      }
                    }
                  }

                  if (
                    staffMasteredArray.length >= program?.masteryStaffMinimum
                  ) {
                    state = 'completed';
                  } else if (
                    staffMasteredArray.length + 1 ===
                    program?.masteryStaffMinimum
                  ) {
                    //check if current state passes vibe check
                    const currentStaffAnalysis = analysisState.filter(element =>
                      element.collectors.includes(collectorIds[0]),
                    );

                    if (
                      !staffMasteredArray.includes(collectorIds[0]) &&
                      currentStaffAnalysis.length + 1 ===
                        program?.masterySessions
                    ) {
                      state = 'completed';
                    } else {
                      state = 'in-treatment';
                    }
                  } else {
                    state = 'in-treatment';
                  }
                } else {
                  state = 'in-treatment';
                }
              } else {
                let consecutiveSessionCount = 0;
                for (let i = analysisState.length - 1; i >= 0; i--) {
                  if (analysisState[i].state !== 'in-treatment') {
                    break;
                  }
                  if (
                    (analysisState[i].average > masteryValue &&
                      program?.adjustment === 'decrease') ||
                    (analysisState[i].average < masteryValue &&
                      program?.adjustment === 'increase')
                  ) {
                    break;
                  } else {
                    consecutiveSessionCount = consecutiveSessionCount + 1;
                  }
                }
                if (consecutiveSessionCount + 1 >= program?.masterySessions) {
                  state = 'completed';
                } else {
                  state = 'in-treatment';
                }
              }
            }
          } else {
            state = 'in-treatment';
          }
        } else if (
          analysisState[analysisState.length - 1]?.state === 'in-maintenance'
        ) {
          let allowance = 0;
          consecutiveSessions = false;
          for (let i = analysisState.length - 1; i >= 0; i--) {
            if (
              (analysisState[i].average > maintenanceValue &&
                program?.adjustment === 'decrease') ||
              (analysisState[i].average < maintenanceValue &&
                program?.adjustment === 'increase')
            ) {
              allowance = allowance + 1;
            }
            if (analysisState[i].state !== 'in-maintenance') {
              break;
            }
            if (analysisState.length - i > program?.maintenanceSessions) {
              consecutiveSessions = true;
              break;
            }
          }
          if (
            consecutiveSessions &&
            allowance <= program?.maintenanceAllowance
          ) {
            state = 'completed';
          } else if (
            consecutiveSessions &&
            allowance >= program?.maintenanceAllowance
          ) {
            state = 'in-treatment';
          } else {
            state = 'in-maintenance';
          }
        } else if (
          analysisState[analysisState.length - 1]?.state === 'completed'
        ) {
          if (program?.maintenance) {
            state = 'in-maintenance';
            switch (program?.maintenanceCadence) {
              case 'daily':
                state = 'in-maintenance';
                break;
              case 'weekly':
                const days = [
                  'Sunday',
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                ];
                const dayNum = new Date(
                  analysisState[analysisState.length - 1].timestamp,
                ).getDay();
                const day = days[dayNum];
                if (day === program?.maintenanceCadenceWeekly) {
                  state = 'in-maintenance';
                } else {
                  state = 'completed';
                }
                break;
              case 'monthly':
                const date = new Date(
                  analysisState[analysisState.length - 1].timestamp,
                ).getDate();
                if (date === program?.maintenanceCadenceMonthly) {
                  state = 'in-maintenance';
                } else {
                  state = 'completed';
                }
                break;
            }
            if (
              state === 'in-maintenance' &&
              program?.maintenanceAllowance === 0 &&
              ((rate < maintenanceValue &&
                program?.adjustment === 'increase') ||
                (rate > maintenanceValue &&
                  program?.adjustment === 'decrease')) &&
              (program?.maintenanceSessions === 1 ||
                program?.maintenanceCadence === 'daily')
            ) {
              state = 'in-treatment';
            }
          } else {
            state = 'completed';
          }
        } else {
          state = 'in-treatment';
        }

        const sessionObject = {
          sessionId,
          sessionDate: startTimestamp,
          average: rate,
          duration: duration,
          collectors: collectorIds,
          timestamp: startTimestamp,
          state,
        };

        analysisState.push(sessionObject);
        analysis[sessionId] = sessionObject;
      }
      break;
    case 'duration':
      for (const {id: sessionId, endTimestamp, userId} of programSessions) {
        let setCollectorIds: string[] = [];
        const sum =
          sessionSets[sessionId]?.reduce((a: number, b: any) => {
            setCollectorIds.push(b?.createdBy);
            return (
              a +
              moment
                .duration(
                  moment(
                    !b.endTimestamp ? moment(endTimestamp) : b.endTimestamp,
                  ).diff(moment(b.startTimestamp)),
                )
                .asSeconds()
            );
          }, 0) || 0;
        setCollectorIds = _.uniq(setCollectorIds).filter(
          (id: string) => id !== '',
        );
        const collectorIds = setCollectorIds.length
          ? setCollectorIds
          : [userId];

        let state;
        let consecutiveSessions = false;
        if (analysisState.length === 0) {
          if (program?.baseline) {
            if (
              program?.baselineSessions === 1 &&
              ((sum >= program?.baselineProbes &&
                program?.adjustment === 'increase') ||
                (sum <= program?.baselineProbes &&
                  program?.adjustment === 'decrease'))
            ) {
              state = 'completed';
            } else {
              state = 'baseline';
            }
          } else {
            if (
              ((sum >= program?.masteryProbes &&
                program?.adjustment === 'increase') ||
                (sum <= program?.masteryProbes &&
                  program?.adjustment === 'decrease')) &&
              program?.masterySessions === 1 &&
              !program?.masteryStaff
            ) {
              state = 'completed';
            } else {
              state = 'in-treatment';
            }
          }
        } else if (
          analysisState[analysisState.length - 1]?.state === 'baseline'
        ) {
          if (analysisState.length === program?.baselineSessions) {
            if (
              program?.masterySessions === 1 &&
              !program?.masteryStaff &&
              ((sum >= program?.masteryValue &&
                program?.adjustment === 'increase') ||
                (sum <= program?.masteryValue &&
                  program?.adjustment === 'decrease'))
            ) {
              state = 'completed';
            } else {
              state = 'in-treatment';
            }
          } else if (analysisState.length + 1 === program?.baselineSessions) {
            for (let i = analysisState.length - 1; i >= 0; i--) {
              if (
                (analysisState[i].average > program?.baselineProbes &&
                  program.adjustment === 'decrease') ||
                (analysisState[i].average < program?.baselineProbes &&
                  program?.adjustment === 'increase')
              ) {
                break;
              }

              if (analysisState.length - i >= program?.baselineSessions - 1) {
                consecutiveSessions = true;
                break;
              }
            }
            if (
              consecutiveSessions ||
              (program?.masterySessions === 1 &&
                ((sum >= program?.masteryValue &&
                  program?.adjustment === 'increase') ||
                  (sum <= program?.masteryValue &&
                    program?.adjustment === 'decrease')) &&
                !program.masteryStaff)
            ) {
              state = 'completed';
            } else {
              state = 'in-treatment';
            }
          } else {
            state = 'baseline';
          }
        } else if (
          analysisState[analysisState.length - 1]?.state === 'in-treatment'
        ) {
          let masterySessions = program?.masterySessions;
          let completedSessions = 0;
          if (program?.masteryStaff) {
            masterySessions =
              program?.masterySessions * program?.masteryStaffMinimum;
          }
          for (let i = analysisState.length - 1; i >= 0; i--) {
            if (analysisState[i].state !== 'in-treatment') {
              break;
            } else {
              completedSessions = completedSessions + 1;
            }
          }

          if (completedSessions + 1 >= masterySessions) {
            consecutiveSessions = false;
            let staffMembers: any[] = [];
            if (
              (sum < program?.masteryValue &&
                program?.adjustment === 'increase') ||
              (sum > program?.masteryValue &&
                program?.adjustment === 'decrease')
            ) {
              state = 'in-treatment';
            } else {
              if (program?.masteryStaff) {
                for (let i = analysisState.length - 1; i >= 0; i--) {
                  if (
                    !staffMembers.some(user =>
                      analysisState[i]?.collectors.includes(user),
                    )
                  ) {
                    analysisState[i].collectors?.forEach((element: any) => {
                      staffMembers.push(element);
                    });
                  }
                }

                if (!staffMembers.some(user => collectorIds.includes(user))) {
                  collectorIds?.forEach(collector => {
                    staffMembers.push(collector);
                  });
                }
                if (staffMembers.length >= program?.masteryStaffMinimum) {
                  let staffMasteredArray: any[] = [];
                  for (const staff of staffMembers) {
                    let staffAnalysis = analysisState.filter(element =>
                      element.collectors.includes(staff),
                    );
                    for (let i = staffAnalysis.length - 1; i >= 0; i--) {
                      if (staffAnalysis[i].state !== 'in-treatment') {
                        break;
                      }
                      if (
                        (staffAnalysis[i].average > program?.masteryValue &&
                          program?.adjustment === 'decrease') ||
                        (staffAnalysis[i].average < program?.masteryValue &&
                          program?.adjustment === 'increase')
                      ) {
                        break;
                      }

                      if (
                        staffAnalysis.length - i >=
                        program?.masterySessions
                      ) {
                        staffMasteredArray.push(staff);
                        break;
                      }
                    }
                  }

                  if (
                    staffMasteredArray.length >= program?.masteryStaffMinimum
                  ) {
                    state = 'completed';
                  } else if (
                    staffMasteredArray.length + 1 ===
                    program?.masteryStaffMinimum
                  ) {
                    //check if current state passes vibe check
                    const currentStaffAnalysis = analysisState.filter(element =>
                      element.collectors.includes(collectorIds[0]),
                    );

                    if (
                      !staffMasteredArray.includes(collectorIds[0]) &&
                      currentStaffAnalysis.length + 1 ===
                        program?.masterySessions
                    ) {
                      state = 'completed';
                    } else {
                      state = 'in-treatment';
                    }
                  } else {
                    state = 'in-treatment';
                  }
                } else {
                  state = 'in-treatment';
                }
              } else {
                let consecutiveSessionCount = 0;
                for (let i = analysisState.length - 1; i >= 0; i--) {
                  if (analysisState[i].state !== 'in-treatment') {
                    break;
                  }
                  if (
                    (analysisState[i].average > program?.masteryValue &&
                      program?.adjustment === 'decrease') ||
                    (analysisState[i].average < program?.masteryValue &&
                      program?.adjustment === 'increase')
                  ) {
                    break;
                  } else {
                    consecutiveSessionCount = consecutiveSessionCount + 1;
                  }
                }
                if (consecutiveSessionCount + 1 >= program?.masterySessions) {
                  state = 'completed';
                } else {
                  state = 'in-treatment';
                }
              }
            }
          } else {
            state = 'in-treatment';
          }
        } else if (
          analysisState[analysisState.length - 1]?.state === 'in-maintenance'
        ) {
          let allowance = 0;
          consecutiveSessions = false;
          for (let i = analysisState.length - 1; i >= 0; i--) {
            if (analysisState[i].state !== 'in-maintenance') {
              break;
            }
            if (
              (analysisState[i].average > program?.maintenanceValue &&
                program?.adjustment === 'decrease') ||
              (analysisState[i].average < program?.maintenanceValue &&
                program?.adjustment === 'increase')
            ) {
              allowance = allowance + 1;
            }

            if (analysisState.length - i >= program?.maintenanceSessions) {
              consecutiveSessions = true;
              break;
            }
          }
          if (
            consecutiveSessions &&
            allowance <= program?.maintenanceAllowance
          ) {
            state = 'completed';
          } else if (
            consecutiveSessions &&
            allowance >= program?.maintenanceAllowance
          ) {
            state = 'in-treatment';
          } else {
            state = 'in-maintenance';
          }
        } else if (
          analysisState[analysisState.length - 1]?.state === 'completed'
        ) {
          if (program?.maintenance) {
            state = 'in-maintenance';
            switch (program?.maintenanceCadence) {
              case 'daily':
                state = 'in-maintenance';
                break;
              case 'weekly':
                const days = [
                  'Sunday',
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                ];
                const dayNum = new Date(
                  analysisState[analysisState.length - 1].timestamp,
                ).getDay();
                const day = days[dayNum];
                if (day === program?.maintenanceCadenceWeekly) {
                  state = 'in-maintenance';
                } else {
                  state = 'completed';
                }
                break;
              case 'monthly':
                const date = new Date(
                  analysisState[analysisState.length - 1].timestamp,
                ).getDate();
                if (date === program?.maintenanceCadenceMonthly) {
                  state = 'in-maintenance';
                } else {
                  state = 'completed';
                }
                break;
            }
            if (
              state === 'in-maintenance' &&
              program?.maintenanceAllowance === 0 &&
              ((sum < program?.maintenanceValue &&
                program?.adjustment === 'increase') ||
                (sum > program?.maintenanceValue &&
                  program?.adjustment === 'decrease')) &&
              (program?.maintenanceSessions === 1 ||
                program?.maintenanceCadence === 'daily')
            ) {
              state = 'in-treatment';
            }
          } else {
            state = 'completed';
          }
        } else {
          state = 'in-treatment';
        }

        const sessionObject = {
          sessionId,
          average: sum,
          state,
          timestamp: endTimestamp,
          collectors: collectorIds,
        };

        analysisState.push(sessionObject);
        analysis[sessionId] = sessionObject;
      }
      break;
    case 'frequency':
      for (const {id: sessionId, userId} of programSessions) {
        const set = sessionSets[sessionId]?.[0];
        const setIds = _.map(sessionSets[sessionId], 'id');
        const setEvents = events.filter((event: any) =>
          setIds.includes(event?.setId),
        );
        const sum = setEvents?.length || 0;

        const setCollectorIds = _.uniq(
          _.map(setEvents, (event: any) => event?.createdBy),
        ).filter((id: string) => id !== '');
        const collectorIds = setCollectorIds.length
          ? setCollectorIds
          : [userId];
        let state;
        let consecutiveSessions = false;
        if (analysisState.length === 0) {
          if (program?.baseline) {
            if (
              program?.baselineSessions === 1 &&
              ((sum >= program?.baselineProbes &&
                program?.adjustment === 'increase') ||
                (sum <= program?.baselineProbes &&
                  program?.adjustment === 'decrease'))
            ) {
              state = 'completed';
            } else {
              state = 'baseline';
            }
          } else {
            if (
              ((sum >= program?.masteryProbes &&
                program?.adjustment === 'increase') ||
                (sum <= program?.masteryProbes &&
                  program?.adjustment === 'decrease')) &&
              program?.masterySessions === 1 &&
              !program?.masteryStaff
            ) {
              state = 'completed';
            } else {
              state = 'in-treatment';
            }
          }
        } else if (
          analysisState[analysisState.length - 1]?.state === 'baseline'
        ) {
          if (analysisState.length === program?.baselineSessions) {
            if (
              program?.masterySessions === 1 &&
              !program?.masteryStaff &&
              ((sum >= program?.masteryValue &&
                program?.adjustment === 'increase') ||
                (sum <= program?.masteryValue &&
                  program?.adjustment === 'decrease'))
            ) {
              state = 'completed';
            } else {
              state = 'in-treatment';
            }
          } else if (analysisState.length + 1 === program?.baselineSessions) {
            for (let i = analysisState.length - 1; i >= 0; i--) {
              if (
                (analysisState[i].average > program?.baselineProbes &&
                  program.adjustment === 'decrease') ||
                (analysisState[i].average < program?.baselineProbes &&
                  program?.adjustment === 'increase')
              ) {
                break;
              }

              if (analysisState.length - i >= program?.baselineSessions - 1) {
                consecutiveSessions = true;
                break;
              }
            }
            if (
              consecutiveSessions ||
              (program?.masterySessions === 1 &&
                ((sum >= program?.masteryValue &&
                  program?.adjustment === 'increase') ||
                  (sum <= program?.masteryValue &&
                    program?.adjustment === 'decrease')) &&
                !program.masteryStaff)
            ) {
              state = 'completed';
            } else {
              state = 'in-treatment';
            }
          } else {
            state = 'baseline';
          }
        } else if (
          analysisState[analysisState.length - 1]?.state === 'in-treatment'
        ) {
          let masterySessions = program?.masterySessions;
          let completedSessions = 0;
          if (program?.masteryStaff) {
            masterySessions =
              program?.masterySessions * program?.masteryStaffMinimum;
          }
          for (let i = analysisState.length - 1; i >= 0; i--) {
            if (analysisState[i].state !== 'in-treatment') {
              break;
            } else {
              completedSessions = completedSessions + 1;
            }
          }

          if (completedSessions + 1 >= masterySessions) {
            consecutiveSessions = false;
            let staffMembers: any[] = [];
            if (
              (sum < program?.masteryValue &&
                program?.adjustment === 'increase') ||
              (sum > program?.masteryValue &&
                program?.adjustment === 'decrease')
            ) {
              state = 'in-treatment';
            } else {
              if (program?.masteryStaff) {
                for (let i = analysisState.length - 1; i >= 0; i--) {
                  if (
                    !staffMembers.some(user =>
                      analysisState[i]?.collectors.includes(user),
                    )
                  ) {
                    analysisState[i].collectors?.forEach((element: any) => {
                      staffMembers.push(element);
                    });
                  }
                }

                if (!staffMembers.some(user => collectorIds.includes(user))) {
                  collectorIds?.forEach(collector => {
                    staffMembers.push(collector);
                  });
                }
                if (staffMembers.length >= program?.masteryStaffMinimum) {
                  let staffMasteredArray: any[] = [];
                  for (const staff of staffMembers) {
                    let staffAnalysis = analysisState.filter(element =>
                      element.collectors.includes(staff),
                    );
                    for (let i = staffAnalysis.length - 1; i >= 0; i--) {
                      if (staffAnalysis[i].state !== 'in-treatment') {
                        break;
                      }
                      if (
                        (staffAnalysis[i].average > program?.masteryValue &&
                          program?.adjustment === 'decrease') ||
                        (staffAnalysis[i].average < program?.masteryValue &&
                          program?.adjustment === 'increase')
                      ) {
                        break;
                      }

                      if (
                        staffAnalysis.length - i >=
                        program?.masterySessions
                      ) {
                        staffMasteredArray.push(staff);
                        break;
                      }
                    }
                  }

                  if (
                    staffMasteredArray.length >= program?.masteryStaffMinimum
                  ) {
                    state = 'completed';
                  } else if (
                    staffMasteredArray.length + 1 ===
                    program?.masteryStaffMinimum
                  ) {
                    //check if current state passes vibe check
                    const currentStaffAnalysis = analysisState.filter(element =>
                      element.collectors.includes(collectorIds[0]),
                    );

                    if (
                      !staffMasteredArray.includes(collectorIds[0]) &&
                      currentStaffAnalysis.length + 1 ===
                        program?.masterySessions
                    ) {
                      state = 'completed';
                    } else {
                      state = 'in-treatment';
                    }
                  } else {
                    state = 'in-treatment';
                  }
                } else {
                  state = 'in-treatment';
                }
              } else {
                let consecutiveSessionCount = 0;
                for (let i = analysisState.length - 1; i >= 0; i--) {
                  if (analysisState[i].state !== 'in-treatment') {
                    break;
                  }
                  if (
                    (analysisState[i].average > program?.masteryValue &&
                      program?.adjustment === 'decrease') ||
                    (analysisState[i].average < program?.masteryValue &&
                      program?.adjustment === 'increase')
                  ) {
                    break;
                  } else {
                    consecutiveSessionCount = consecutiveSessionCount + 1;
                  }
                }
                if (consecutiveSessionCount + 1 >= program?.masterySessions) {
                  state = 'completed';
                } else {
                  state = 'in-treatment';
                }
              }
            }
          } else {
            state = 'in-treatment';
          }
        } else if (
          analysisState[analysisState.length - 1]?.state === 'in-maintenance'
        ) {
          let allowance = 0;
          consecutiveSessions = false;
          for (let i = analysisState.length - 1; i >= 0; i--) {
            if (analysisState[i].state !== 'in-maintenance') {
              break;
            }
            if (
              (analysisState[i].average > program?.maintenanceValue &&
                program?.adjustment === 'decrease') ||
              (analysisState[i].average < program?.maintenanceValue &&
                program?.adjustment === 'increase')
            ) {
              allowance = allowance + 1;
            }

            if (analysisState.length - i >= program?.maintenanceSessions) {
              consecutiveSessions = true;
              break;
            }
          }
          if (
            consecutiveSessions &&
            allowance <= program?.maintenanceAllowance
          ) {
            state = 'completed';
          } else if (
            consecutiveSessions &&
            allowance >= program?.maintenanceAllowance
          ) {
            state = 'in-treatment';
          } else {
            state = 'in-maintenance';
          }
        } else if (
          analysisState[analysisState.length - 1]?.state === 'completed'
        ) {
          if (program?.maintenance) {
            state = 'in-maintenance';
            switch (program?.maintenanceCadence) {
              case 'daily':
                state = 'in-maintenance';
                break;
              case 'weekly':
                const days = [
                  'Sunday',
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                ];
                const dayNum = new Date(
                  analysisState[analysisState.length - 1].timestamp,
                ).getDay();
                const day = days[dayNum];
                if (day === program?.maintenanceCadenceWeekly) {
                  state = 'in-maintenance';
                } else {
                  state = 'completed';
                }
                break;
              case 'monthly':
                const date = new Date(
                  analysisState[analysisState.length - 1].timestamp,
                ).getDate();
                if (date === program?.maintenanceCadenceMonthly) {
                  state = 'in-maintenance';
                } else {
                  state = 'completed';
                }
                break;
            }
            if (
              state === 'in-maintenance' &&
              program?.maintenanceAllowance === 0 &&
              ((sum < program?.maintenanceValue &&
                program?.adjustment === 'increase') ||
                (sum > program?.maintenanceValue &&
                  program?.adjustment === 'decrease')) &&
              (program?.maintenanceSessions === 1 ||
                program?.maintenanceCadence === 'daily')
            ) {
              state = 'in-treatment';
            }
          } else {
            state = 'completed';
          }
        } else {
          state = 'in-treatment';
        }

        const sessionObject = {
          sessionId,
          average: sum,
          timestamp: set?.createdAt,
          collectors: collectorIds,
          state,
        };

        analysisState.push(sessionObject);
        analysis[sessionId] = sessionObject;
      }
      break;
  }
  return {
    id: program.id,
    name: program.name,
    type: program.type,
    method: program.method,
    baseline: program.baseline,
    baselineCorrectProbes: program.baselineCorrectProbes,
    baselineProbes: program.baselineProbes,
    masteryValue: program.masteryValue,
    maintenance: program.maintenance,
    maintenanceValue: program.maintenanceValue,
    analysis: analysis,
    analysisState: sessionMessage ? analysis[session?.id] : analysisState,
  };
};
